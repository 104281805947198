export const menuData = [
  {
    type: "food",
    group: [
      {
        list: [
          {
            category: "THE GRILL",
            items: [
              {
                name: "TORINEGI",
                description: "chicken thigh & green onion",
                price: "90",
              },
              {
                name: "BUTA BARA",
                description: "6 hour cooked pork belly",
                price: "100",
              },
              {
                name: "GYU GRILL",
                description: "imported Steak, yakiniku sauce",
                price: "150",
              },
              {
                name: "WHITE FISH",
                description: "locally caught fish, teriyaki sauce",
                price: "100",
              },
            ],
          },
          {
            category: "APPETIZERS",
            items: [
              {
                name: "EDAMAME",
                description: "soy beans cooked plain or sweet garlic",
                price: "80",
              },
              {
                name: "OCTOPUS BOKKEUM",
                description: "spicy korean stir-fry",
                price: "120",
              },
              {
                name: "CHICKEN KARAAGE",
                description: "fried chicken & curry mayo",
                price: "180",
              },
              {
                name: "GYOZA PORK or VEGETARIAN",
                description: "flash fried dumplings",
                price: "210",
              },
              {
                name: "SPAM MUSUBI",
                description: "grilled spam, seasoned rice",
                price: "130",
              },
              {
                name: "NORI CHEESE FRIES",
                description: "lemon aioli or ketchup/mayo",
                price: "210",
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            category: "RICE BOWLS",
            items: [
              {
                name: "KAISENDON STIR-FRY",
                description: "mix seafood, garlic miso butter glaze",
                price: "300",
              },
              {
                name: "OYAKODON",
                description: "chicken thigh w/ egg and donbori sauce",
                price: "240",
              },
              {
                name: "BUTADON",
                description: "pork cutlet in butashoga sauce on rice",
                price: "240",
              },
              {
                name: "GYUDON",
                description: "imported steak w/ egg & donbori sauce",
                price: "320",
              },
              {
                name: "KATSUDON CHICKEN or PORK",
                description: "breaded meat, egg & donbori sauce",
                price: "260",
              },
              {
                name: "CURRY KATSU PORK, CHICKEN or FISH",
                description: "curry sauce and vegetables",
                price: "270",
              },
              {
                name: "CURRY with VEGETABLE PROTEIN",
                description: "curry sauce and vegetables",
                price: "220",
              },
              {
                name: "***Subtitute noodles, remove rice*** ",
                description: "",
                price: "40",
              },
            ],
          },
          {
            category: "ALA CARTE",
            items: [
              {
                name: "YAKISOBA PORK or VEGETARIAN",
                description: "stir-fried noodles",
                price: "250",
              },
              {
                name: "YASAI VEGETABLE STIR-FRY",
                description: "vegetables and pork stir-fry",
                price: "200",
              },
              {
                name: "SHRIMP & VEG TEMPURA",
                description: "tempura sauce",
                price: "300",
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            category: "SALADS",
            items: [
              {
                name: "YUZU SALAD",
                description: "lettuce, tomatoes, creamy yuzu",
                price: "200",
              },
              {
                name: "WANTAW HOUSE SALAD",
                description: "cabbage, sesame dressing",
                price: "140",
              },
            ],
          },
          {
            category: "SOUPS",
            items: [
              {
                name: "COUNTRY STYLE MISO SOUP",
                description: "",
                price: "60",
              },
              {
                name: "MUSHROOM VEGI RAMEN",
                description: "",
                price: "230",
              },
              {
                name: "PORK TONKUTSO RAMEN",
                description: "",
                price: "250",
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            category: "SIDINGS",
            items: [
              {
                name: "CHASHU PORK",
                description: "",
                price: "80",
              },
              {
                name: "MARINATED EGG",
                description: "",
                price: "40",
              },
              {
                name: "SAUCE AND DIPS",
                description: "",
                price: "25",
              },
            ],
          },
          {
            category: "RICE",
            items: [
              {
                name: "STEAMED JAPANESE RICE",
                description: "",
                price: "60",
              },
              {
                name: "CHAHAN FRIED RICE VEGETARIAN",
                description: "",
                price: "90",
              },
              {
                name: "CHAHAN FRIED RICE PORK",
                description: "",
                price: "90",
              },
              {
                name: "GARLIC FRIED RICE",
                description: "",
                price: "90",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "drinks",
    group: [
      {
        list: [
          {
            category: "SHAKES, SMOOTHIES AND JUICE",
            items: [
              {
                name: "MANGO MILK SHAKE",
                description: "",
                option: 1,
                price: "180",
              },
              {
                name: "MANGO JUICE",
                description: "",
                option: 2,
                price: "160",
              },
              {
                name: "OH MY WOW",
                description: "peach, mango, yakult",
                option: 0,
                price: "200",
              },
              {
                name: "CHOCOLATE MILK SHAKE",
                description: "",
                option: 1,
                price: "190",
              },
              {
                name: "COCONUT MILK SHAKE",
                description: "",
                option: 2,
                price: "180",
              },
              {
                name: "STRAWBERRY MILK SHAKE",
                description: "",
                option: 1,
                price: "190",
              },
              {
                name: "PINEAPPLE MILK SHAKE",
                description: "",
                option: 1,
                price: "180",
              },
              {
                name: "PINEAPPLE JUICE",
                description: "",
                option: 2,
                price: "160",
              },
              {
                name: "PINA COLADA SMOOTHIE",
                description: "",
                option: 2,
                price: "180",
              },
              {
                name: "COKE IN CAN",
                description: "",
                option: 0,
                price: "80",
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            category: "ESPRESSO DRINKS & BLENDED LATTES",
            items: [
              {
                name: "ESPRESSO SINGLE",
                description: "",
                option: 2,
                price: "100",
              },
              {
                name: "ESPRESSO DOUBLE",
                description: "",
                option: 2,
                price: "150",
              },
              {
                name: "CAFE AMERICANO",
                description: "",
                option: 2,
                price: "120",
              },
              {
                name: "CAPUCCINO",
                description: "",
                option: 1,
                price: "150",
              },
              {
                name: "CAFE LATTE",
                description: "",
                option: 1,
                price: "170",
              },
              {
                name: "CAFE MOCHA",
                description: "",
                option: 1,
                price: "160",
              },
              {
                name: "CARAMEL MACCHIATO",
                description: "",
                option: 1,
                price: "180",
              },
              {
                name: "FLAT WHITE",
                description: "",
                option: 1,
                price: "150",
              },
              {
                name: "BLENDED VANILLA LATTE",
                description: "",
                option: 1,
                price: "200",
              },
              {
                name: "BLENDED HAZELNUT LATTE",
                description: "",
                option: 1,
                price: "220",
              },
              {
                name: "BLENDED COCONUT LATTE",
                description: "",
                option: 1,
                price: "220",
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            category: "COCKTAILS & BEER",
            items: [
              {
                name: "ICED COCONUT",
                description: "Rum based",
                option: 2,
                price: "250",
              },
              {
                name: "MARGARITA",
                description: "Tequila based",
                option: 2,
                price: "250",
              },
              {
                name: "VODKA SODA",
                description: "Vodka based",
                option: 2,
                price: "250",
              },
              {
                name: "CUCUMBER BASIL MOJITO",
                description: "Soju based",
                option: 2,
                price: "130",
              },
              {
                name: "YOGURT SOJU",
                description: "Soju based",
                option: 0,
                price: "130",
              },
              {
                name: "PILSEN SMB",
                description: "",
                option: 0,
                price: "70",
              },
              {
                name: "FLAVORED BEER SMB",
                description: "",
                option: 0,
                price: "70",
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            category: "SAKE BOTTLES",
            items: [
              {
                name: "OZEKI NAMA",
                description: "",
                option: 0,
                price: "390",
              },
              {
                name: "KIZAKURA NIGORI",
                description: "",
                option: 0,
                price: "420",
              },
              {
                name: "KIZAKURA PINK",
                description: "",
                option: 0,
                price: "550",
              },
              {
                name: "ONE CUP OZEKI",
                description: "",
                option: 0,
                price: "230",
              },
              {
                name: "NAKANO UMESHI PLUM WINE",
                description: "",
                option: 0,
                price: "260",
              },
              {
                name: "SENJYU KABOTO",
                description: "",
                option: 0,
                price: "1440",
              },
            ],
          },
        ],
      },
    ],
  },
]
