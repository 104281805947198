import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeadData from "../data/HeadData"
import HeroBanner from "../components/HeroBanner"
import Header from "../components/section/Header"
import ContentContainer from "../components/ContentContainer"
import Content from "../components/Content"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { menuData } from "../data/MenuData"
import Footer from "../components/section/Footer"

const MenuPage = () => {
  const menuImages = useStaticQuery(graphql`
    {
      item1: file(relativePath: { eq: "menu-img-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      item2: file(relativePath: { eq: "menu-img-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      item3: file(relativePath: { eq: "menu-img-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const menuImage1 = convertToBgImage(getImage(menuImages.item1))
  const menuImage2 = convertToBgImage(getImage(menuImages.item2))
  const menuImage3 = convertToBgImage(getImage(menuImages.item3))

  return (
    <>
      <HeadData title="Menu" />
      <HeroBanner />
      <Header />

      <ContentContainer className="bg-orange1">
        <Content className="grid grid-cols-3 lg:pt-16">
          <BackgroundImage
            {...menuImage1}
            className="w-full h-40 sm:min-h-bannerSubSM lg:h-72"
          >
            &nbsp;
          </BackgroundImage>
          <BackgroundImage
            {...menuImage2}
            className="w-full h-40 sm:min-h-bannerSubSM lg:h-72"
          >
            &nbsp;
          </BackgroundImage>
          <BackgroundImage
            {...menuImage3}
            className="w-full h-40 sm:min-h-bannerSubSM lg:h-72"
          >
            &nbsp;
          </BackgroundImage>
        </Content>

        <Content className="pt-14 text-center">
          <div className="text-center w-full text-gray1">
            <p className="text-6xl sm:text-5xl font-montserrat">WANTAW</p>
            <p className="tracking-77 text-sm sm:text-xl font-montserrat">RESTAURANT</p>
          </div>
        </Content>

        <Content>
          {menuData.map((section, index) => {
            return (
              <div
                key={`menuSection${index}`}
                className="border border-white border-solid m-5 sm:m-10 rounded-lg px-5 font-montserrat"
              >
                <div className="grid grid-cols-1 lg:grid-cols-2 text-xs gap-x-5 ">
                  {section.group.map((group, index) => {
                    return (
                      <div key={`menuGroup${index}`}>
                        {group.list.map((list, index) => {
                          return (
                            <div
                              key={`menuList${index}`}
                              className="flex flex-col gap-y-3 mt-10 mb-5 sm:my-10"
                            >
                              <div>
                                <p className="font-bold inline-block text-orange2 border-b-2 border-orange2 pr-1">
                                  {list.category}
                                </p>
                              </div>
                              <div className="flex flex-col gap-y-1">
                                {list.items.map((item, index) => {
                                  return (
                                    <div
                                    key={`menuItem${index}`}
                                      className={`flex flex-row ${
                                        item.name.startsWith("*")
                                          ? "font-normal pt-5"
                                          : "font-bold"
                                      }`}
                                    >
                                      <div className="pb-1 lg:pb-2 pr-2 flex flex-col xl:flex-row">
                                        {item.name}
                                        {item.description?.length > 0 ? (
                                          <div className="font-normal">
                                            <span className="hidden xl:inline pl-1">
                                              -{" "}
                                            </span>
                                            {item.description}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="flex-grow flex flex-row-reverse text-left font-bold">
                                        <div className="w-8">{item.price}</div>
                                        {item.option > 0 ? (
                                          <div>
                                            <div className="relative top-1 pr-2">
                                              {item.option === 1 ? (
                                                <StaticImage
                                                  alt=""
                                                  src="../images/option-1.png"
                                                  placeholder="blurred"
                                                />
                                              ) : (
                                                <StaticImage
                                                  alt=""
                                                  src="../images/option-2.png"
                                                  placeholder="blurred"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                {index + 1 === menuData.length ? (
                  <div className="w-full flex flex-col text-xs pb-10 items-start sm:items-center justify-center">
                    <div className="flex flex-col sm:flex-row gap-x-4 pb-5 ">
                      <div className="font-bold flex flex-row py-1">
                        <div className="relative sm:top-1 pr-2">
                          <StaticImage
                            alt=""
                            src="../images/option-1.png"
                            placeholder="blurred"
                          />
                        </div>
                        CAN BE MADE VEGAN
                      </div>
                      <div className="font-bold  flex flex-row py-1">
                        <div className="relative sm:top-1 pr-2">
                          <StaticImage
                            alt=""
                            src="../images/option-2.png"
                            placeholder="blurred"
                          />
                        </div>
                        VEGAN OPTION
                      </div>
                    </div>
                    <div className="italic w-full text-left pr-20 sm:pr-0 sm:text-center">
                      Menu prices and menu items are subject to change without
                      prior notice
                    </div>
                  </div>
                ) : null}
              </div>
            )
          })}
        </Content>
      </ContentContainer>
      <Footer />
    </>
  )
}

export default MenuPage
